<template>
    <div class="card">
        <div class="card-image">
            <img :src="require(`../images/${entry.filename}`)" alt="" class="thumbnail"/>
        </div>
        <div class="card-details">
            <p>{{ entry.title }} by {{ entry.author }}</p>
            <p class="download-text">{{ entry.downloads }} downloads</p>
            <div class="tag-container">
                <button class="btn-tag">{{ entry.tags }}</button>
                <button class="btn-tag">+2</button>
            </div>
        </div>
        <div class="button-container">
            <va-button class="btn-download">Download</va-button>
            <router-link to="/galleryentry" class="btn-details">View Details</router-link>
        </div>
    </div>
    
</template>

<script>
export default {
    name: 'GalleryCard',
    props: {
        entry: {
            type: Object,
            default: () => ({
                title: "A Very Cool Map",
                author: "Calvin Cartographer",
                filename: "mountain-arena.jpg",
                dimensions: "23x34",
                tags: "encounter",
                downloads: "420",
            })
        }
    }
}
</script>

<style scoped>
.card {
    background-color: #fff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    width: 250px;
    padding: 10px;
    margin: 10px 3px 10px 3px;
    border-radius: 10px;
    box-sizing: content-box;
}
.thumbnail {
    width: 250px;
}
.card-details {
    text-align: start;
    margin-top: 5px;
}
    .download-text {
        color: #4A4A4A;
        font-size: 12px;
        line-height: 14px;
    }

    .tag-container {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
    }
        .btn-tag {
            border-radius: 46px;
            border: 1px solid #000000;
            background: transparent;
            padding: 4px;
            margin-top: 5px;
            gap: 5px;
        }

.button-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
    .btn-download {
        margin: 5px 0 5px 0;
    }
    .btn-details {
        color: #4e7ae7;
    }

</style>