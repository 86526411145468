<template>
  <div class="welcome-container">
    <img class="welcome-logo" src="../assets/logo.png" />
    <div class="welcome-text">
      <h1>Welcome To Beholder Maps</h1>
      <p>A Dungeon Master's best friend, Beholder Maps is your one stop shop for thousands of Dungeons & Dragons maps. From battlemaps to city and region maps, our database is created by the community, for the community.</p>
      <p>Browse by tons of tags, from Forest, Cave and City, all the way down to the small scenarios, like Elf, Crypt, and more.</p>
      <router-link class="nav-link" to="/gallery">Browse All Maps</router-link>
    </div>
  </div>
  <div class="middle-container">
    <div class="left-content">
      <h1>Jump In!</h1>
      <p>With some of our most popular map tags.</p>
    </div>
    <div class="right-content">
      <div class="tag-wrapper">
        <button class="btn-tag">Forest</button>
        <button class="btn-tag">Mountain</button>
        <button class="btn-tag">City</button>
        <button class="btn-tag">Desert</button>
      </div>
      <router-link class="tag-link" to="/">View All Tags ></router-link>
    </div>
  </div>
  <div class="preview-container">
    <div class="preview-text">
      <h1>Most Popular Maps</h1>
      <p>Check out these community favorites!</p>
    </div>
    <div class="preview-wrapper">
      <GalleryCard />
      <GalleryCard />
      <GalleryCard />
      <GalleryCard />
    </div>
    <div class="preview-links">
      <router-link class="nav-link" to="/gallery">Browse All Maps</router-link>
      <span> | </span>
      <router-link class="nav-link" to="/">View By Tag</router-link>
    </div>
  </div>
</template>

<script>
import GalleryCard from '../components/GalleryCard'
export default {
  name: 'Home',
  components: {
    GalleryCard,
  }
}
</script>

<style scoped>
h1 {
  font-size: 32px;
}
.nav-link {
  color: #4e7ae7;
}
.nav-link:hover {
  filter: brightness(1.3);
  cursor: pointer;
}

.welcome-container {
  height: 40vh;
  width: 100%;
  position: relative;
  display: inline-block;
}
  .welcome-logo {
    position: absolute;
    height: 100%;
    right: 0;
    filter: invert(95%) sepia(7%) saturate(202%) hue-rotate(187deg) brightness(90%) contrast(89%);
  }
  .welcome-text {
    z-index: 1;
    min-width: 425px;
    max-width: 60%;
    position: absolute;
    top: 25%;
  }
  .welcome-text > h1 {
    text-align: start;
  }
  .welcome-text > p {
    margin: 20px 0 20px 0;
    text-align: start;
  }
  .welcome-text > a {
    align-self: center;
  }

.middle-container {
  position: relative;
  background-color: #4E7AE7;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  margin: 30px 0 60px 0;
  padding: 20px;
  display: flex;
  justify-content:space-between;
  line-height: 20px;
  color: #fff;
}
  .left-content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: start;
    flex: 1;
  }

  .right-content {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    flex-direction: column;
    flex: 2;
  }
    .tag-wrapper {
      margin-bottom: 10px;
    }
      .btn-tag {
        background: rgba(255, 255, 255, 0.07);
        border: 1px solid #FFFFFF;
        box-sizing: border-box;
        border-radius: 46px;
        margin: 3px 10px 3px 10px;
        padding: 8px;
        color: #fff;
      }
      .tag-link {
        align-self: flex-end;
        margin-top: 10px;
        color: #fff;
      }

.preview-container {
  position: relative;
  margin-bottom: 30px;
  line-height: 20px;
}
  .preview-text {
    text-align: start;
    left: 3%;
    margin-bottom: 10px;
  }

  .preview-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .preview-links {
    color: grey;
    margin-top: 20px;
  }


/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 1200px) {
  .welcome-container {
    height: 35vw;
  }
    .welcome-text {
      top: 15%;
    }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) { 
  .welcome-container {
    height: 40vw;
  }
    .welcome-text {
      top: 10%;
    }
    .welcome-logo {
      max-width: 100%;
      height: auto;
    }
}

/* Landscape phones and down */
@media (max-width: 480px) {
  h1 {
    font-size: 24px;
  }
  p {
    font-size: 14px;
  }

  .welcome-container {
    height: 60vw;
  }
    .welcome-text {
      min-width: 100%;
      top: 0;
    }

  .btn-tag {
    margin: 3px 3px 3px 3px;
    padding: 6px;
    font-size: 14px;
  }
}
</style>



